import { CloseButton, Combobox, Loader, TextInput, useCombobox } from '@mantine/core';
import React, { useContext, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { fetchProductColors } from '../../../commons/utils/ActionsSubject';
import { ProductInfoDTO } from '../../../commons/interfaces/Products';
import { ExtendedInputProps } from '../../../commons/interfaces/SelectInterfaces';
import GlobalContext from '../../providers/GlobalContext';
import { useUncontrolled } from '@mantine/hooks';
import ScopeContext from '../../providers/ScopeContext';


const ProductSelect: React.FC<ExtendedInputProps> = (props) => {
    const [loading, setLoading] = useState(false);
    const [optionSelected, setOptionSelected] = useState(false);
    const [data, setData] = useState<ProductInfoDTO[] | null>(null);
    const [colorFindPayload, setColorFindPayload] = useState<string | null>(null);
    const [empty, setEmpty] = useState(false);
    const abortController = useRef<AbortController>();
    const {globalContext,} = useContext(GlobalContext);
    const {scope} = useContext(ScopeContext);

    const combobox = useCombobox({
        onDropdownClose: () => combobox.resetSelectedOption(),
        
    });

    const [_value, handleChange] = useUncontrolled({
        value: props.value,
        defaultValue: props.defaultValue,
        finalValue: 'Final',
        onChange: props.onChange,
    });

    function getAsyncData(searchQuery: string, signal: AbortSignal) {
        return new Promise<ProductInfoDTO[]>((resolve, reject) => {
            signal.addEventListener('abort', () => {
                reject(new Error('Request aborted'));
            });
            if(searchQuery){
                axios(
                    {
                        url: `${document.referrer}${globalContext.parentContext}/products?action=find-product&criteria=${searchQuery.toUpperCase()}`,
                        method: 'get',
                        withCredentials: true,
                    }).then((response) => {
                        const data = response.data as ProductInfoDTO[];
                        resolve(
                            data.filter((product) => product.productId.toLowerCase().includes(searchQuery.toLowerCase())).slice(
                            0,
                            15
                        )
                        );
                    });
            }
        });
    }

    const fetchOptions = (query: string) => {
        abortController.current?.abort();
        abortController.current = new AbortController();
        setLoading(true);
    
        getAsyncData(query, abortController.current.signal)
            .then((result) => {
                setData(result);
                setLoading(false);
                setEmpty(result.length === 0);
                abortController.current = undefined;
                
            }).catch(() => {});
    };

    useEffect(() => {
        if(colorFindPayload !== null){
            fetchProductColors.next({productId: colorFindPayload, scopeContext: scope.scopeContext});
        }
    }, [colorFindPayload]);
    
    const options = (data || []).map((item) => (
        <Combobox.Option value={`${item.productId}@${item.description}`} key={item.productId}>
            {`${item.productId} - ${item.description}`}
        </Combobox.Option>
        ));

    const getRightSection = () => {
        if (loading) {
            return <Loader size={18} />;
        }else{
            if(_value){
                return (<CloseButton
                size="sm"
                onMouseDown={(event) => event.preventDefault()}
                onClick={() => {
                    setColorFindPayload('');
                    setOptionSelected(false);
                    handleChange('');
                    fetchProductColors.next({productId: '', scopeContext: scope.scopeContext});
                    // props.onChange('');
                }}
                aria-label="Clear value"
                />);
            }else{
                return <Combobox.Chevron />;
            }
        }
    }

    const getValueFromData = (value: string) => {
        if(data && data.length > 0){
            const product = data.find((item) => item.productId === value);
            if(product && optionSelected){
                return `${product?.productId} - ${product?.description}`;
            }
        }
        return value;
    }
    
    return (
        <Combobox
            onOptionSubmit={(optionValue) => {
                const splitVal = optionValue.split("@");
                // setValue(`${splitVal[0]} - ${splitVal[1]}`);
                handleChange(splitVal[0]);
                setColorFindPayload(splitVal[0]);
                setOptionSelected(true);
                // props.onChange(splitVal[0]);
                combobox.closeDropdown();
            }}
            withinPortal={false}
            store={combobox}
        >
        <Combobox.Target>
            <TextInput
                id={props.id}
                label={props.label}
                value={getValueFromData(_value)}
                onKeyDown={(event) => {
                    if (optionSelected) {
                        event.preventDefault();
                    }
                }}
                onChange={(event) => {
                    handleChange(event.currentTarget.value);
                    fetchOptions(event.currentTarget.value);
                    combobox.resetSelectedOption();
                    combobox.openDropdown();
                }}
                onClick={() => combobox.openDropdown()}
                onFocus={() => {
                    combobox.openDropdown();
                }}
                onBlur={() => combobox.closeDropdown()}
                rightSection={getRightSection()}
            />
        </Combobox.Target>
    
        <Combobox.Dropdown hidden={data === null}>
            <Combobox.Options>
                {options}
                {empty && <Combobox.Empty>No results found</Combobox.Empty>}
            </Combobox.Options>
            </Combobox.Dropdown>
        </Combobox>
    );
};

export default ProductSelect;
