import React, { useCallback, useContext, useEffect, useState } from 'react';
import TableCheckActionContext from '../providers/TableCheckActionContext';
import { Button, Flex, List, Space, Text } from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faUnlockKeyhole } from '@fortawesome/free-solid-svg-icons';
import useAxios from '../../hooks/useAxios';
import { modals } from '@mantine/modals';
import ScopeContext from '../providers/ScopeContext';
import { ContextEnum } from '../../commons/enums/RequestContextEnum';
import { refreshTable } from '../../commons/utils/ActionsSubject';
import useRights from '../../hooks/useRights';

interface CheckBoxActionBarProps {
    controlId: string;
}

interface ActionDetailDTO{
    requestId: number;
    resultDetail: string;
}

interface PedidoActionDetailRSDTO{
    result: string;
    failedRequests: ActionDetailDTO[];
    successfulRequests: ActionDetailDTO[];
}

const CheckBoxActionBar: React.FC<CheckBoxActionBarProps> = ({controlId}) => {
    const { scope } = useContext(ScopeContext);
    const { checkedRequests } = useContext(TableCheckActionContext);
    const { showControlledInput } = useRights();

    const { data, executeRequest, loading, responseError } = useAxios({});
    const [dataFromModal, setDataFromModal] = useState<PedidoActionDetailRSDTO>();

    const [ activeEvent, setActiveEvent ] = useState<'MarkAsLost'|'MarkAsActive'|'AutoCEO'>();

    useEffect(() => {
        if (data || dataFromModal) {
            const response = dataFromModal?dataFromModal as PedidoActionDetailRSDTO : data as PedidoActionDetailRSDTO;
            if(response.failedRequests.length > 0 && response.successfulRequests.length > 0){
                showAlertNotification(response.successfulRequests, response.failedRequests);
            }else if(response.failedRequests.length > 0){
                showAlertNotification(response.successfulRequests, response.failedRequests);
            }else if(response.successfulRequests.length > 0){
                showSuccessNotification(response.successfulRequests);
            }
        }
    }, [data, dataFromModal]);

    const refreshTableResults = () => {
        switch(activeEvent){
            case 'AutoCEO':
                if(scope.scopeContext === ContextEnum.QUOTATION){
                    refreshTable.next({scopeContext: ContextEnum.ACTIVE_REQUEST});
                }
                break;
            case 'MarkAsLost':
                refreshTable.next({scopeContext: ContextEnum.LOST_QUOTATION});
                break;
            case 'MarkAsActive':
                refreshTable.next({scopeContext: ContextEnum.QUOTATION});
                break;
        }
        refreshTable.next({scopeContext: scope.scopeContext});
    }

    const showAlertNotification = (successRequests: ActionDetailDTO[], failedRequests: ActionDetailDTO[]) => {
        const allFailed = failedRequests.length > 0 && successRequests.length === 0; 
        modals.openContextModal({
        size: 'lg',
        modal: 'alert',
        title: allFailed ? 'No se pudo aplicar la operacion.': 'Operacion parcialmente aplicada.',
        onClose: () => refreshTableResults(),
        innerProps: {
            modalBody: (
                <>
                    <Text>
                        Las siguientes cotizaciones o pedidos no puedieron procesarse satisfactoriamente.   
                    </Text>
                    
                    <Space h="md" />
                    <List>
                        {failedRequests.map((request, index) => {
                            return (
                                <List.Item key={index}>
                                    {request.requestId} - {request.resultDetail}
                                </List.Item>
                                )
                            })
                        }
                    </List>
                </>
            )
        },
        })
    }

    const showSuccessNotification = (successRequests: ActionDetailDTO[]) => {
        modals.open({
            title: 'Proceso culminado satisfactoriamente.',
            onClose: () => refreshTableResults(),
            children: (
                <>
                    <Text>
                        {`Todos los pedidos o cotizaciones (${successRequests.map((requestDetail) => requestDetail.requestId).join(', ')}) fueron procesados correctamente.`}   
                    </Text>
                    <Button fullWidth onClick={() => modals.closeAll()} mt="md">
                        Entendido
                    </Button>
                </>
            ),
        });
    }

    const handleAutoCeo = useCallback(() => {
        setActiveEvent('AutoCEO');
        const requestArray = checkedRequests.map((request) => ({
            pedido: request.idPedido,
            tkmov: request.tkmov,
            empresa: request.idEmpresa,
            }));
        executeRequest(`/sales/quotation-actions?action=auth-ceo-batch`, 'post', {baseActionRQDTOs: requestArray});
    }, [checkedRequests, executeRequest]);


    const processModal = (data:any) => {
        setDataFromModal(data);
    }

    const openMarkAsLostConfirmModal = useCallback(() => {
        setActiveEvent('MarkAsLost');
        openMarkAsLostOrActivateConfirmModal('Marcar Cotización(es) como Perdida(s)');
    }, [checkedRequests]);
    
    const openActivateConfirmModal = useCallback(() => {
        setActiveEvent('MarkAsActive');
        openMarkAsLostOrActivateConfirmModal('Reactivar Cotización(es)', true);
    }, [checkedRequests]);

    const openMarkAsLostOrActivateConfirmModal = useCallback((title: string, reactivate: boolean = false) => {
        modals.openContextModal({
            modal: 'markAsLostOrActivate',
            title: title,
            innerProps: {
                multiRequests: true,
                reactivate,
                request: checkedRequests,
                confirmCallback: processModal
            },
            });
    }, [checkedRequests]);

    const show = useCallback(() => {
        return showControlledInput({rightControlId: controlId, disabled: false});
    }, [showControlledInput, controlId]);

    return (
        <>
            {
                checkedRequests.length > 0 && (
                    <>
                        <Flex
                        pl={10}
                        mih={50}
                        bg='var(--mantine-color-blue-light)'
                        gap="sm"
                        justify="flex-start"
                        align="center"
                        direction="row"
                        wrap="wrap"
                        >
                            <p>{checkedRequests.length} Seleccionadas</p>
                            {(show() && scope.scopeContext !== ContextEnum.LOST_QUOTATION) && 
                                <Button variant="light" leftSection={<Text>T</Text>} onClick={() => handleAutoCeo()}>
                                    Autorizar Remisión
                                </Button>
                            }
                            
                            { scope.scopeContext === ContextEnum.QUOTATION && (
                                <Button
                                variant="light"
                                leftSection={<FontAwesomeIcon icon={faLock} />}
                                disabled={!showControlledInput({rightControlId: 'BtnGridCerrarPed', disabled: false})}
                                onClick={() => openMarkAsLostConfirmModal()} >
                                Cotización Perdida
                            </Button>
                            )}

                            { scope.scopeContext === ContextEnum.LOST_QUOTATION && (
                                <Button variant="light" leftSection={<FontAwesomeIcon icon={faUnlockKeyhole} />} onClick={() => openActivateConfirmModal()} >
                                Reactivar Cotización
                            </Button>
                            )}
                            
                    </Flex>
                    </>
                )
            }
        </>
    );
};

export default CheckBoxActionBar;