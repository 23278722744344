import { FC } from "react";
import { Input, Title, Button, Flex } from "@mantine/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faFilter, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { getHotkeyHandler } from "@mantine/hooks";
import { filterZoneBackground } from "../../styles";

type SearchBarProps = {
  title: string;
  onToggle: () => void;
  wildCardUpdateCallback: (wildcard: string) => void;
  searchCallback: () => void;
};

const SearchBar: FC<SearchBarProps> = ({ title, onToggle, wildCardUpdateCallback, searchCallback}) => {

  const handleFind = () => {
    searchCallback();
  }

  return (
    <Flex styles={filterZoneBackground} px="sm" py="xs" direction="row" justify="space-between">
      <Flex
        gap="xs"
        justify="flex-start"
        align="center"
        direction="row"
        wrap="wrap"
        px="sm"
        py="xs"
      >
        <Title order={3} style={{ color: "#54595E" }}>
          {title}
        </Title>
      </Flex>
      <Flex
        styles={filterZoneBackground}
        gap="xs"
        justify="flex-end"
        align="center"
        direction="row"
        wrap="wrap"
        px="sm"
        py="xs"
      >
        <Input
          radius="xs"
          placeholder="Buscar"
          onChange={(event) => wildCardUpdateCallback(event.target.value)}
          
          onKeyDown={getHotkeyHandler([
            ['Enter', handleFind]
          ])}
          
          rightSection={<FontAwesomeIcon icon={faMagnifyingGlass}/>}
        />
        <Button
          bg="white"
          leftSection={<FontAwesomeIcon icon={faFilter} />}
          rightSection={<FontAwesomeIcon icon={faCaretDown} />}
          variant="outline"
          onClick={onToggle}
        >
          Filtros
        </Button>
      </Flex>
    </Flex>
  );
};

export default SearchBar;
