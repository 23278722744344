import React, { createContext, useState } from 'react';
import { SessionUserInfoDTO } from '../../commons/interfaces/Users';

export interface GlobalContextContent{
    role: string;
    tkmov: string;
    parentContext: string;
    parentResourceName: string;
    userInfo: SessionUserInfoDTO | null;
}

interface GlobalContextState {
    globalContext: GlobalContextContent;
    setGlobalContext: React.Dispatch<React.SetStateAction<GlobalContextContent>>;
}

const defaultValue: GlobalContextState = {
    globalContext: {role: '', tkmov: '', parentContext: '', parentResourceName: '', userInfo: null},
    setGlobalContext: () => {} // Función vacía como valor predeterminado
};


const GlobalContext = createContext<GlobalContextState>(defaultValue);

type GlobalContextProviderProps = {
    role: string;
    tkmov: string;
    parentContext: string;
    parentResourceName: string;
    userInfo?: SessionUserInfoDTO | null;
    children?: React.ReactNode
};


export const GlobalContextProvider: React.FC<GlobalContextProviderProps> = ({ children, role, tkmov, parentContext, parentResourceName, userInfo}) => {
    const [globalContext, setGlobalContext] = useState<GlobalContextContent>({
        role,
        tkmov,
        parentResourceName,
        parentContext,
        userInfo: userInfo || null
    });
    
    return (
        <GlobalContext.Provider value={{globalContext, setGlobalContext}}>
            {children}
        </GlobalContext.Provider>
    );
};

export default GlobalContext;