import { Menu, Text } from '@mantine/core';
import React, { useCallback } from 'react';
import useMenuActions from '../../../hooks/useMenuActions';
import useRights from '../../../hooks/useRights';
import { IPedido } from '../../../commons/interfaces/Pedidos';

interface MenuItemActionsProps {
    disabled?: boolean;
    controlId: string;
    request: IPedido;
}

const ItemPrint: React.FC<MenuItemActionsProps> = (props) => {
    const { showControlledInput } = useRights();

    const { handlePrintReport } = useMenuActions({
        pedido: props.request.idPedido,
        tkmov: props.request.tkmov,
        empresa: props.request.idEmpresa
    });

    const show = useCallback(() => {
        return showControlledInput({rightControlId: props.controlId, disabled: props.disabled});
    }, [showControlledInput]);

    return (
        <>
            <Menu.Item closeMenuOnClick={false} disabled={!show()}>
                <Menu shadow="md" position="left" withArrow>
                    <Menu.Target>
                        <Text size="sm">Imprimir</Text>
                    </Menu.Target>
                    
                    <Menu.Dropdown >
                        <Menu.Item onClick={() => handlePrintReport(true)}>Con importes</Menu.Item>
                        <Menu.Item onClick={() => handlePrintReport(false)}>Sin importes</Menu.Item>
                    </Menu.Dropdown>
                
                </Menu>
            </Menu.Item> 
        </>
    );
};

export default ItemPrint;