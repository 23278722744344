import { useState, useCallback, useContext } from "react";
import axios, { AxiosError, AxiosResponse, AxiosRequestConfig, ResponseType } from "axios";
import { showLoadingOverlaySubject } from "../commons/utils/ActionsSubject";
import useNotifier from "./useNotifier";
import GlobalContext from "../components/providers/GlobalContext";

interface UAxiosResponse {
  data: any | null;
  executeRequest: (url: string, method?: "get" | "post" | "put" | "delete", body?: any, responseType?: ResponseType) => void;
  responseError: string | null;
  loading: boolean;
}

interface UseAxiosArgs {
  headers?: any;
}

function useAxios({
  headers = {},
}: UseAxiosArgs): UAxiosResponse {
  const [data, setData] = useState<any | null>(null);
  const [responseError, setResponseError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const { processAxiosError } = useNotifier();
  const { globalContext } = useContext(GlobalContext);

  const executeRequest = useCallback(async (url: string, method: string = "get", body?: any, responseType?: ResponseType) => {
    responseError && setResponseError(null);
    data && setData(null);
    try {
      showLoadingOverlaySubject.next(true);
      setLoading(true);
      const config: AxiosRequestConfig = {
        url: `${document.referrer}${globalContext.parentContext}${url}`,
        method,
        data: body,
        headers,
        withCredentials: true
      };
      if (responseType) {
        config.responseType = responseType;
      }
      const response: AxiosResponse = await axios(config);
      // Question: this must be (responseType == 'blob')?
      if (responseType == 'blob') {
        const pdfUrl = URL.createObjectURL(response.data);
        window.open(pdfUrl, '_blank');
      } else {
        setData(response.data);
      }
    } catch (error) {
      const result = processAxiosError(error as AxiosError<string>);
      setResponseError(result);
    } finally {
      setLoading(false);
      showLoadingOverlaySubject.next(false);
    }
  }, []);

  return { data, executeRequest, loading, responseError };
}

export default useAxios;

