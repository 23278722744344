import { Menu, Text } from '@mantine/core';
import React, { useCallback, useContext } from 'react';
import { IPedido } from '../../../commons/interfaces/Pedidos';
import useRights from '../../../hooks/useRights';
import useRequest from '../../../hooks/useRequest';
import { notifications } from '@mantine/notifications';
import ScopeContext from '../../providers/ScopeContext';
import { refreshTable } from '../../../commons/utils/ActionsSubject';

interface MenuItemActionsProps {
    disabled?: boolean;
    controlId: string;
    request: IPedido;
}

const ItemCancelSupply: React.FC<MenuItemActionsProps> = ({disabled, controlId, request}) => {
    const {scope} = useContext(ScopeContext);
    const { showControlledInput } = useRights();
    const { executeRequest } = useRequest();


    const show = useCallback(() => {
        return showControlledInput({rightControlId: controlId, disabled: disabled});
    }, [showControlledInput]);

    const handleCancelSupply = useCallback(() => {
        executeRequest({
            url: `/warehouse/packaging-supply?action=cancel-supply`,
            method: 'POST',
            body: {
                pedido: request.idPedido,
                tkmov: request.tkmov,
                empresa: request.idEmpresa
            },
            callback: () => {
                notifications.show({
                    title: "Resultado",
                    message: "Surtido cancelado correctamente",
                    color: "green",
                });
                refreshTable.next({scopeContext: scope.scopeContext});
            }
        });
    }, []);

    return (
        <Menu.Item disabled={!show()} onClick={handleCancelSupply}>
            <Text size="sm">Cancelar surtido</Text>
        </Menu.Item>
    );
};

export default ItemCancelSupply;