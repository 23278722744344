import React, { CSSProperties, useCallback, useEffect, useState } from 'react';
import { IPedido } from '../../../commons/interfaces/Pedidos';
import { ActionIcon, Table, Tooltip } from '@mantine/core';
import { getButtonSStyles } from '../../../commons/utils/ActionButtonsStyleHelper';
import useRights from '../../../hooks/useRights';

interface ActionButtonSProps {
    request: IPedido;
}

const ActionButtonS: React.FC<ActionButtonSProps> = ({ request }) => {
    const { showControlledInput } = useRights();

    const isEnabled = useCallback(() => {
        return showControlledInput({rightControlId: 'BtnGridAutVta', disabled: false});
    }, [showControlledInput]);

    return (
        <Table.Td pr={3} pl={3}>
            <Tooltip label="Surtido">
                <ActionIcon variant="light" size="sm" aria-label="Settings" style={getButtonSStyles(request)} disabled={!isEnabled()}>
                    S
                </ActionIcon>
            </Tooltip>
        </Table.Td>
    );
};

export default ActionButtonS;